import React, { useEffect, useState } from "react";
// import {
//     useJsApiLoader,
// } from "@react-google-maps/api";

import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Alert, Badge, Card, CardBody, CardHeader, Container, Input, Label, Row, Col, CardFooter, CardTitle } from "reactstrap";
import { AttAdminApi } from "../../helpers/att_api_helper";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { AdminVenueDto } from "src/helpers/att-api-dtos/venue/admin-venue.dto.interface";
import { WebsiteCrawlerCacheRecord } from "src/helpers/att-api-dtos/admin/venue/admin-website-crawler-cache-record.interface";

interface ManageBuilderPageProps {
    google: Object
    history: any
    match: {
        params: {
        }
    }
}

// const libraries: any[] = ["places"]

const ManageBuilderPage = (props: ManageBuilderPageProps) => {

    const [venueWebsiteUrl, setVenueWebsiteUrl] = useState<string>('')
    const [venueAddress, setVenueAddress] = useState<string | undefined>(undefined)
    const [selectedVenue, setSelectedVenue] = useState<AdminVenueDto | null>(null)

    // Job Id and state, used to poll the crawler job:
    const [jobId, setJobId] = useState<string | null>(null)
    const [jobState, setJobState] = useState<'completed' | 'loading' | null>(null)

    const {
        data: startCrawlResult,
        error: startCrawlResultError,
        isLoading: startCrawlResultLoading,
        refetch: startCrawlResultRefetch,
    } = useQuery<
        { jobId: string },
        Error
    >({
        queryKey: [`build-venue-from-url`, venueWebsiteUrl],
        queryFn: () => AttAdminApi.venues.admin.build.crawlSite({
            websiteUrl: venueWebsiteUrl,
            venueAddress,
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    useEffect(() => {
        if (startCrawlResult) {
            setJobId(startCrawlResult.jobId)
            setJobState('loading')
        }
    }, [startCrawlResult])

    const {
        data: crawlerResults,
        error: crawlerResultsError,
        isLoading: isLoadingCrawlerResults,
    } = useQuery<
        WebsiteCrawlerCacheRecord,
        Error
    >({
        queryKey: [`pollSiteCrawlerJon`, jobId],
        queryFn: () => AttAdminApi.venues.admin.build.pollSiteCrawlerJon(jobId!),
        refetchOnWindowFocus: false,
        enabled: Boolean(jobId?.length && jobState === 'loading'),
        refetchInterval: data => (data && data.completed ? false : 2000),
    });

    useEffect(() => {
        if (crawlerResults?.completed) {
            setJobState('completed')
        }
    }, [crawlerResults])

    const {
        data: createdVenueData,
        error: createdVenueError,
        isLoading: isLoadingCreatingVenue,
        refetch: createVenue,
    } = useQuery<
        { venue: AdminVenueDto },
        Error
    >({
        queryKey: [`create-venue`, selectedVenue],
        queryFn: () => AttAdminApi.venues.admin.build.create(selectedVenue!),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    const {
        data: updatedVenueData,
        error: updatedVenueError,
        // isLoading: isLoadingUpdatingVenue,
        refetch: updateVenue,
    } = useQuery<
        { venue: AdminVenueDto },
        Error
    >({
        queryKey: [`update-venue`, selectedVenue],
        queryFn: () => AttAdminApi.venues.admin.build.update(selectedVenue!),
        refetchOnWindowFocus: false,
        enabled: false,
    });

    /*
    const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDgohtZ966jJlToDhVXQlJ_egGA7gvEkXc",
        libraries,
    });
    */

    const isLoadingOrPolling = isLoadingCrawlerResults || startCrawlResultLoading || jobState === 'loading'

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Venue Builder | ATT</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Venue Builder (Website Scanner)" />
                    <Row>
                        <Card>
                            <CardHeader>
                                <div className="row">
                                    <p>
                                        Use this tool to scan a website to gather it's details about a specific restaurant and booking provider links.
                                    </p>
                                    <p>
                                        This tool can be used to create or update existing restaurant in AllTheTables.
                                    </p>
                                    <Row>
                                        <div className="mb-3">
                                            <Input
                                                disabled={isLoadingOrPolling}
                                                value={venueWebsiteUrl}
                                                onChange={(e) => {
                                                    setVenueWebsiteUrl(e.target.value);
                                                }}
                                                placeholder="https://venue-website.com/hello"
                                                type="text"
                                                className="form-control"
                                                id={`display-venue-search-website-url`}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Input
                                                disabled={isLoadingOrPolling}
                                                value={venueAddress}
                                                onChange={(e) => {
                                                    setVenueAddress(e.target.value);
                                                }}
                                                placeholder="Venues' address (optional)"
                                                type="text"
                                                className="form-control"
                                                id={`display-venue-search-website-address`}
                                            />
                                        </div>
                                        <Link
                                            onClick={(e) => {
                                                if (!isLoadingOrPolling) {
                                                    startCrawlResultRefetch();
                                                }
                                                e.preventDefault();
                                            }}
                                            to="#"
                                            className="btn btn-primary m-2"
                                        >
                                            {
                                                isLoadingOrPolling
                                                    ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Scanning website, this can take a minute...</>
                                                    : 'Scan Website'
                                            }
                                        </Link>
                                    </Row>

                                    {
                                        Boolean(crawlerResultsError) && <Alert color="danger">
                                            {crawlerResultsError}
                                        </Alert>
                                    }

                                    {
                                        Boolean(startCrawlResultError) && <Alert color="danger">
                                            {startCrawlResultError}
                                        </Alert>
                                    }

                                </div>
                            </CardHeader>
                            <CardBody className="px-0">
                                {
                                    createdVenueData && <Alert color="success">
                                        Venue created successfully
                                    </Alert>
                                }
                                {
                                    updatedVenueData && <Alert color="success">
                                        Venue updated successfully
                                    </Alert>
                                }
                                {
                                    Boolean(crawlerResults && crawlerResults?.results?.length) && (
                                        <CardBody>
                                            {crawlerResults?.results?.map((result) => {
                                                return (
                                                    <Row key={result.venue.id}>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <CardTitle>{result.venue.name}</CardTitle>
                                                                </CardHeader>
                                                                <CardBody>

                                                                    {
                                                                        Boolean(result.venue.id?.length || createdVenueData?.venue?.id)
                                                                            ? <div>Existing venue: <code className="highlighter-rouge">
                                                                                {result.venue.id || createdVenueData?.venue?.id}
                                                                            </code>

                                                                                {
                                                                                    updatedVenueError && <Badge className="bg-danger">
                                                                                        Error: {updatedVenueError.message}
                                                                                    </Badge>
                                                                                }
                                                                            </div>
                                                                            : <div>
                                                                                Not in database &nbsp;
                                                                                {
                                                                                    createdVenueError && <Badge className="bg-danger">
                                                                                        Error: {createdVenueError.message}
                                                                                    </Badge>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </CardBody>
                                                                <CardFooter>
                                                                    {
                                                                        Boolean(result.venue.id?.length || createdVenueData?.venue?.id?.length)
                                                                            ? <>
                                                                                <button className="btn btn-secondary m-1" color="primary" onClick={() => {
                                                                                    window.location.assign(`/venue-directory/${result?.venue?.id || createdVenueData?.venue?.id}`)
                                                                                }}>Click to view</button>
                                                                                <button className="btn btn-primary m-1" color="info" onClick={() => {
                                                                                    setSelectedVenue(result?.venue || createdVenueData?.venue)

                                                                                    if (!selectedVenue) {
                                                                                        return
                                                                                    }

                                                                                    updateVenue()

                                                                                    // setSelectedVenue(null)
                                                                                }}>Click to update</button>
                                                                            </>
                                                                            : <>
                                                                                <button className="btn btn-primary m-1" color="primary" onClick={async () => {
                                                                                    setSelectedVenue(result.venue)
                                                                                    if (!selectedVenue) {
                                                                                        return
                                                                                    }
                                                                                    await createVenue()
                                                                                   // setSelectedVenue(null)
                                                                                }}>{
                                                                                        isLoadingCreatingVenue
                                                                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                            : 'Click to save'
                                                                                    }
                                                                                </button>
                                                                            </>
                                                                    }
                                                                </CardFooter>
                                                            </Card>
                                                            <p>
                                                                {result.venue?.location.address.line1}
                                                                {result.venue?.location?.address?.line2}
                                                                {result.venue?.location?.address?.street}
                                                                {result.venue?.location?.address?.town}
                                                                {result.venue?.location?.address?.countryCode}
                                                            </p>
                                                            <Input
                                                                className="form-control"
                                                                type="textarea"
                                                                placeholder="Description"
                                                                rows={16}
                                                                value={result.venue?.description}
                                                                id="formrow-display-description-input"
                                                            />

                                                            {
                                                                result.venue.cuisines.length === 0 && <div>No Cuisines Detected</div>
                                                            }

                                                            {
                                                                result.venue.cuisines.map((cuisine) => {
                                                                    return <div
                                                                        className="btn btn-outline-secondary"
                                                                        key={`cuisine-${cuisine.id}`}
                                                                    >
                                                                        {cuisine.name}
                                                                    </div>
                                                                })
                                                            }

                                                            <div className="btn btn-outline-secondary">
                                                                {
                                                                    Boolean(result?.venue?.social?.instagram?.length)
                                                                        ? result?.venue?.social?.instagram
                                                                        : 'No Instagram'
                                                                }
                                                            </div>
                                                            <div className="btn btn-outline-secondary">
                                                                {
                                                                    Boolean(result?.venue?.social?.facebook?.length)
                                                                        ? result?.venue?.social?.facebook
                                                                        : 'No Facebook'
                                                                }
                                                            </div>
                                                            <div className="btn btn-outline-secondary">
                                                                {
                                                                    Boolean(result?.venue?.social?.twitter?.length)
                                                                        ? result?.venue?.social?.twitter
                                                                        : 'No Twitter'
                                                                }
                                                            </div>
                                                            {
                                                                result.venue.openingTimes.length === 0 && <Alert color="warning">
                                                                    No opening times set
                                                                </Alert>
                                                            }
                                                            {
                                                                result.venue.openingTimes.map((openingTime) => {
                                                                    return <div>
                                                                        <Label className="form-label" htmlFor={`display-opening-time-${openingTime.day}-input`}>
                                                                            {openingTime.day}:
                                                                            <ul>
                                                                                {
                                                                                    openingTime.periods.map((period, i) => {
                                                                                        return <li>From {period.startHHmm} to {period.finishHHmm}</li>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </Label>
                                                                    </div>
                                                                })
                                                            }
                                                            {
                                                                <p>Website: {result.venue.contact.website || '-'}</p>
                                                            }
                                                            {
                                                                <p>Email: {result.venue.contact.email || '-'}</p>
                                                            }
                                                            {
                                                                <p>Phone: {result.venue.contact.phone || '-'}</p>
                                                            }

                                                            {
                                                                <p>Facebook: {result.venue.social.facebook || '-'}</p>
                                                            }
                                                            {
                                                                <p>Instagram: {result.venue.social.instagram || '-'}</p>
                                                            }
                                                            {
                                                                <p>Twitter: {result.venue.social.twitter || '-'}</p>
                                                            }
                                                        </Col>
                                                        <Col xl={6}>
                                                            {
                                                                result.bookingLinks.map((provider) => {
                                                                    return (
                                                                        <Card key={provider.providerId}>

                                                                            <CardHeader>
                                                                                <h4 className="card-title">{provider.providerType} Link</h4>
                                                                            </CardHeader>

                                                                            <CardBody>

                                                                                <Row className="mb-4">
                                                                                    <Label
                                                                                        htmlFor="horizontal-provider-id-input"
                                                                                        className="col-sm-3 col-form-label"
                                                                                    >
                                                                                        {provider.providerType} ID
                                                                                    </Label>
                                                                                    <Col sm={9}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="horizontal-provider-id-input"
                                                                                            disabled={true}
                                                                                            value={provider.providerId}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row className="mb-4">
                                                                                    <Label
                                                                                        htmlFor="horizontal-provider-url-slug-input"
                                                                                        className="col-sm-3 col-form-label"
                                                                                    >
                                                                                        {provider.providerType} Url SLug
                                                                                    </Label>
                                                                                    <Col sm={9}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="horizontal-provider-url-slug-input"
                                                                                            value={provider.providerSlug}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>

                                                                            </CardBody>
                                                                        </Card>
                                                                    );
                                                                })
                                                            }

                                                            {
                                                                !Boolean(result.venue.images.length) && <Alert color="warning">
                                                                    No images found
                                                                </Alert>
                                                            }
                                                            {
                                                                result.venue.images.map((image) => {
                                                                    return <img
                                                                        className="img-thumbnail offer-image-preview"
                                                                        alt={'Thumbnail'}
                                                                        src={image.url}
                                                                        data-holder-rendered="true"
                                                                    />
                                                                })
                                                            }

                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </CardBody>
                                    )

                                }
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageBuilderPage;
